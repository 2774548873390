import { template as template_e9684c2333a342ec97f94b3d2bd3ed12 } from "@ember/template-compiler";
const FKLabel = template_e9684c2333a342ec97f94b3d2bd3ed12(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
