import { template as template_45b167ee352d4e7e9b7aea51181cf03a } from "@ember/template-compiler";
const WelcomeHeader = template_45b167ee352d4e7e9b7aea51181cf03a(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
