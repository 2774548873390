import { template as template_575b5982309640a497f4931d27cb3737 } from "@ember/template-compiler";
const FKText = template_575b5982309640a497f4931d27cb3737(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
