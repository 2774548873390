import { template as template_e8130215f6bc4939a804a3471c6628c2 } from "@ember/template-compiler";
const FKControlMenuContainer = template_e8130215f6bc4939a804a3471c6628c2(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
