import { template as template_bd5038f00d5e4d4fabb25e19558abe7c } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const RepliesCell = template_bd5038f00d5e4d4fabb25e19558abe7c(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="posts"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="replies"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RepliesCell;
